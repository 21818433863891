import scrollIntoView from "scroll-into-view-if-needed";

const handleScrollViewport = (id) => {
  try {
    const element = document.getElementById(id);

    if (element) {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (height <= 768 && width <= 640) {
        scrollIntoView(element, {
          behavior: "smooth",
          block: "start",
          inline: "center",
        });
      } else {
        scrollIntoView(element, {
          behavior: "smooth",
          scrollMode: "if-needed",
          block: "start",
          inline: "center",
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const handleScroll = (id) => {
  try {
    const element = document.getElementById(id);

    if (element) {
      scrollIntoView(element, {
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export { handleScrollViewport, handleScroll };
