import useFormContext from "../hooks/useFormContext.jsx";
import { NUMBER_OF_QUESTIONS } from "../utils/constants";

import "./ProgressBar.scss";

const ProgressBar = () => {
  const { questionIndex } = useFormContext();

  return (
    <div className="progress-bar">
      <div
        className="actual-progress"
        role="progressbar"
        aria-valuemin={0}
        aria-valuemax={NUMBER_OF_QUESTIONS}
        aria-valuenow={questionIndex}
        style={{ minWidth: `${(questionIndex * 100) / NUMBER_OF_QUESTIONS}%` }}
      />
    </div>
  );
};

export default ProgressBar;
