import "./GoogleRecaptcha.scss";

const GoogleRecaptcha = () => {
  return (
    <div className="google-recaptcha">
      This site is protected by reCAPTCHA and the Google{" "}
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  );
};

export default GoogleRecaptcha;
