import { useEffect } from "react";

const useRadarAutocomplete = (onSelection, disabled) => {
  useEffect(() => {
    try {
      window.Radar.ui.autocomplete({
        container: "autocomplete",
        /* width: "600px",
        maxHeight: "600px", */
        near: null,
        debounceMS: 700,
        threshold: 6,
        limit: 8,
        onSelection,
        placeholder: "Search address",
        responsive: true,

        layers: ["address"],
        countryCode: "US",
        showMarkers: true,
        markerColor: "#ACBDC8",
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      const radarInput = document.getElementsByClassName(
        "radar-autocomplete-input",
      )[0];

      radarInput.disabled = disabled;
    } catch (error) {
      console.log(error);
    }
  }, [disabled]);
};

export default useRadarAutocomplete;
