import useEverflow from "../../hooks/useEverflow.jsx";
import useAnalytics from "../../hooks/useAnalytics.jsx";
import SpaceJet from "./SpaceJet.jsx";
import Adplugg from "./Adplugg.jsx";

import "./ThankYou.scss";

const ThankYou3 = () => {
  useEverflow(404);
  useAnalytics("typage");

  const randomNumber = Math.ceil(Math.random() * 2);

  return (
    <div className="thank-you-container">
      {randomNumber === 1 ? <SpaceJet /> : <Adplugg />}
    </div>
  );
};

export default ThankYou3;
