import { useContext } from "react";

import FormContext from "../contexts/FormContext.jsx";

const useFormContext = () => {
  const {
    questionIndex,
    formData,
    hiddenData,
    trustedLeadData,
    urlParams,
    tyUrlParams,
    thankYouRanking,
    variant,
    solarData,
    isLoading,
    handleHiddenData,
    handleUrlParams,
    handleTyUrlParams,
    handleTrustedLead,
    handleThankYouRanking,
    handleUpdateForm,
    handleNextQuestion,
    handleVariant,
    handleSolarData,
    handleIsLoading,
  } = useContext(FormContext);

  return {
    questionIndex,
    formData,
    hiddenData,
    trustedLeadData,
    urlParams,
    tyUrlParams,
    thankYouRanking,
    variant,
    solarData,
    isLoading,
    handleHiddenData,
    handleUrlParams,
    handleTyUrlParams,
    handleTrustedLead,
    handleThankYouRanking,
    handleUpdateForm,
    handleNextQuestion,
    handleVariant,
    handleSolarData,
    handleIsLoading,
  };
};

export default useFormContext;
