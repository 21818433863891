import CommonBox from "./CommonBox.jsx";
import Input from "../Inputs/Input.jsx";
import InputError from "../Messages/InputError.jsx";

const InputBox = ({ inputMode, mask, hidden, register, error, ...props }) => {
  return (
    <CommonBox>
      <Input
        {...props}
        inputMode={inputMode}
        mask={mask}
        hidden={hidden}
        register={register}
      />

      <InputError error={error} />
    </CommonBox>
  );
};

export default InputBox;
