import React from "react";

import { companyList } from "../utils/companyList";

import "./CompanyList.scss";

const CompanyList = () => {
  return (
    <section className="company-list">
      <article>
        <h3>Matched companies included but not limited to:</h3>

        <ul>
          {React.Children.toArray(companyList.map((name) => <li>{name}</li>))}
        </ul>
      </article>
    </section>
  );
};

export default CompanyList;
