import { useEffect } from "react";
import useFormContext from "./useFormContext.jsx";

const useVariant = () => {
  const { handleVariant } = useFormContext();

  useEffect(() => {
    handleVariant(window.variant);
  }, []);
};

export default useVariant;
