import Paragraph from "./Paragraph.jsx";

const InputError = ({ error }) => {
  return (
    <>
      {error ? (
        <Paragraph text={error} className="error" />
      ) : (
        <Paragraph text="hidden placeholder" className="error hidden" />
      )}
    </>
  );
};

export default InputError;
