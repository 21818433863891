import useAdplugg from "../../hooks/useAdplugg.jsx";
import useEverflowPixel from "../../hooks/useEverflowPixel.jsx";

const Adplugg = () => {
  useAdplugg();
  useEverflowPixel();

  return (
    <>
      <p className="thank-you-title">
        A Gutter professional will be reaching out very soon! Please take a look
        as you might be interested in any of the following
      </p>

      <div className="adplugg-tag" data-adplugg-zone="yhg_typage" />
    </>
  );
};

export default Adplugg;
