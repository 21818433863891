import useFormContext from "../hooks/useFormContext.jsx";
import useSubmit from "../hooks/useSubmit.jsx";
import ProjectDetails from "./Forms/ProjectDetails.jsx";
import AboutHome from "./Forms/AboutHome.jsx";
import Personal from "./Forms/Personal.jsx";
import ThankYou3 from "./Messages/ThankYou3.jsx";
import ThankYou2 from "./Messages/ThankYou2.jsx";
import ThankYou from "./Messages/ThankYou.jsx";
import MonthlyBill from "./Forms/MonthlyBill.jsx";

const CurrentForm = () => {
  const { questionIndex } = useFormContext();

  const { submitQuestion, submitLastQuestion, isLoading } = useSubmit();

  const formsArray = [
    <ThankYou3 />,
    <ProjectDetails onSubmit={submitQuestion} />,
    <AboutHome onSubmit={submitQuestion} />,
    <Personal onSubmit={submitLastQuestion} isLoading={isLoading} />,
    <ThankYou2 />,
  ];

  /* const formsArray = [
    <MonthlyBill onSubmit={submitQuestion} />,
    <Personal onSubmit={submitLastQuestion} isLoading={isLoading} />,
    <ThankYou2 />,
    <ProjectDetails onSubmit={submitQuestion} />,
    <AboutHome onSubmit={submitQuestion} />,
    <Personal onSubmit={submitLastQuestion} isLoading={isLoading} />,
    <ThankYou2 />,
  ]; */

  return formsArray[questionIndex];
};

export default CurrentForm;
