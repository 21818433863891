import { useForm } from "react-hook-form";

import useEverflow from "../../hooks/useEverflow.jsx";
import useAnalytics from "../../hooks/useAnalytics.jsx";
import Select from "../Inputs/Select.jsx";
import Button from "../Inputs/Button.jsx";
import {
  houseTypeSelectOptions,
  ownHomeSelectOptions,
} from "../../utils/enumOptions";

import "./Form.scss";

const AboutHome = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEverflow();
  useAnalytics("address");

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-container">
      <h2>Would you tell us more about your home?</h2>

      <div className="form-inputs-container">
        <h3>Do you own your home?</h3>

        <Select
          name="own_home"
          register={register}
          error={errors.own_home}
          options={ownHomeSelectOptions}
          placeholder="Select Option"
        />
      </div>

      <div className="form-inputs-container">
        <h3>How would you describe your house?</h3>

        <Select
          name="house_type"
          register={register}
          error={errors.house_type}
          options={houseTypeSelectOptions}
          placeholder="Select Option"
        />
      </div>

      <Button text="NEXT" type="submit" disabled={Object.keys(errors).length} />
    </form>
  );
};

export default AboutHome;
