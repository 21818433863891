import { useEffect, useState } from "react";

const useSpaceJet = () => {
  const [isLoading, setIsLoading] = useState(true);

  function checkGoogleOptimizeLoading() {
    if (window.google_optimize !== undefined) {
      const variant = "a";
    } else {
      setTimeout(checkGoogleOptimizeLoading, 100);
    }
  }

  const checkScriptFinish = () => {
    const intervalId = setInterval(() => {
      const ads = document.getElementsByClassName("sjcards");

      if (ads.length) {
        setIsLoading(false);

        clearInterval(intervalId);
      }
    }, 1000);

    setTimeout(() => {
      const ads = document.getElementsByClassName("sjcards");

      if (!ads.length) {
        setIsLoading(false);

        //! VOLVER A VER agregar typage default en caso que falle spacejet

        clearInterval(intervalId);
      }
    }, 5000);
  };

  useEffect(() => {
    const scriptId = "spacejetads-script";

    if (!document.getElementById(scriptId)) {
      checkScriptFinish();
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://api.spacejetads.com/js/site.js?pubid=562707";
      document.head.appendChild(script);
    }
  }, []);

  return { isLoading };
};

export default useSpaceJet;

/* I have to implement a script on a React.js project, they asked me to do this:

"You can place this script in the head section of your page-

```
<script src="https://api.spacejetads.com/js/site.js?pubid=562707"></script>
```

And then place this tag inline on the page where you want it to appear:

```
<ins id="spacejetads"></ins>
```
"

I've done this but I get the following error:

`error TypeError: document.getElementById(...).setHTML is not a function at site.js?pubid=562707:12:60`

The site.jsscript is:

```
var tags = document.querySelector('script[src*="spacejetads.com/js/"]');
var pubid = unescape(tags.src).split("pubid=")[1].split("&")[0];

var requestOptions = {
    method: 'GET',
    redirect: 'follow'
};
fetch("https://spacejetads.com/api/pubads/" + pubid, requestOptions)
    .then(response => response.text())
    .then(result => document.getElementById('spacejetads').setHTML(result))
    .catch(error => console.log('error', error));
```

For what I've seen on https://developer.mozilla.org, `setHTML()` is an experimental method and it is not supported on many browsers. I want to add this script manually inside an React.js component, but changing `setHTML` to `innerHTML`, how can I achieve this? */
