export const materialSelectOptions = [
  {
    value: "copper",
    label: "Copper",
  },
  {
    value: "galvanized_steel",
    label: "Galvanized Steel",
  },
  {
    value: "pvc",
    label: "PVC",
  },
  {
    value: "seamless_aluminum",
    label: "Seamless Aluminum",
  },
  {
    value: "wood",
    label: "Wood",
  },
  {
    value: "unsure",
    label: "Not Sure",
  },
];

export const scopeSelectOptions = [
  {
    value: "install",
    label: "Install",
  },
  {
    value: "repair",
    label: "Repair",
  },
  {
    value: "replace",
    label: "Replace",
  },
  {
    value: "guard",
    label: "Gutter Guard",
  },
  {
    value: "install_guard",
    label: "Install + Gutter Guard",
  },
  {
    value: "repair_guard",
    label: "Repair + Gutter Guard",
  },
];

export const startDateSelectOptions = [
  {
    value: "immediately",
    label: "Immediately",
  },
  {
    value: "1-6_months",
    label: "1-6 Months",
  },
  {
    value: "unsure",
    label: "Not Sure",
  },
];

export const houseTypeSelectOptions = [
  {
    value: "single_family",
    label: "Single Family",
  },
  {
    value: "multi-family",
    label: "Multi-Family House",
  },
  {
    value: "mobile_home",
    label: "Mobile Home",
  },
  {
    value: "townhome",
    label: "Townhome",
  },
  {
    value: "condominium",
    label: "Condominium",
  },
  {
    value: "duplex",
    label: "Duplex",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const ownHomeSelectOptions = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "yes",
    label: "No, but I am allowed to make changes",
  },
  {
    value: "no",
    label: "No",
  },
];
