import { isEmail } from "validator";

import { validateStateCode } from "./validateStateCode";
import {
  nameRegex,
  phoneRegex,
  streetAddressRegex,
  zipCodeRegex,
} from "../utils/regex";

const addressValidation = {
  required: {
    value: true,
    message: "Enter your street address",
  },
  maxLength: {
    value: 64,
    message: "Street Address must have less than 65 characters",
  },
  pattern: {
    value: streetAddressRegex,
    message: "Enter a valid Street Address",
  },
};

const cityValidation = {
  required: {
    value: true,
    message: "Enter a city",
  },
  maxLength: {
    value: 64,
    message: "City must have less than 65 characters",
  },
};

const stateValidation = {
  required: {
    value: true,
    message: "Enter a State",
  },
  minLength: {
    value: 2,
    message: "State must have 2 characters",
  },
  validate: (value) => validateStateCode(value) || "Enter a valid State",
};

const emailValidation = {
  required: {
    value: true,
    message: "Enter your Email",
  },
  minLength: {
    value: 6,
    message: "Email must be 6 characters length at least",
  },
  maxLength: {
    value: 64,
    message: "Email must have less than 65 characters",
  },
  validate: {
    isEmail: (value) => isEmail(value) || "Enter a valid email",
  },
};

const firstNameValidation = {
  required: {
    value: true,
    message: "Enter your first name",
  },
  pattern: {
    value: nameRegex,
    message: "Enter a valid first name",
  },
  minLength: {
    value: 2,
    message: "First name must be 2 characters length at least",
  },
  maxLength: {
    value: 25,
    message: "First name must have less than 26 characters",
  },
};

const lastNameValidation = {
  required: {
    value: true,
    message: "Enter your last name",
  },
  pattern: {
    value: nameRegex,
    message: "Enter a valid last name",
  },
  minLength: {
    value: 2,
    message: "Last name must be 2 characters length at least",
  },
  maxLength: {
    value: 25,
    message: "Last name must have less than 26 characters",
  },
};

const phoneValidation = {
  required: {
    value: true,
    message: "Enter a phone number",
  },
  pattern: {
    value: phoneRegex,
    message: "Enter a valid phone number",
  },
  validate: {
    notAllowNumberOneAtFirstPlace: (value) =>
      !/^\(1/.test(value) || "Phone number must not start with number 1",
  },
};

const zipCodeValidation = {
  required: {
    value: true,
    message: "Enter zip code",
  },
  pattern: {
    value: zipCodeRegex,
    message: "Zip code must have 5 digits",
  },
};

export {
  addressValidation,
  cityValidation,
  stateValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  phoneValidation,
  zipCodeValidation,
};
