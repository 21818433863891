import { ReactComponent as Phone } from "../assets/svg/phone.svg";

import "./PhoneSection.scss";

const PhoneSection = () => {
  return (
    <section className="phone-section" id="phoneblock">
      <div className="phone-text">
        <p>Speak to an Agent</p>
        <a href="tel:8555351361" className="phone-number">
          (855) 535-1361
        </a>
        <p>Agents available now</p>
      </div>

      <Phone />
    </section>
  );
};

export default PhoneSection;
