import { ReactComponent as Close } from "../../assets/svg/close.svg";

import "./Modal.scss";

const Modal = ({ children, isOpen, closeModal }) => {
  const handleModalContainerClick = (e) => e.stopPropagation();

  return (
    <article className={`modal ${isOpen && "is-open"}`} onClick={closeModal}>
      <div className="modal-container" onClick={handleModalContainerClick}>
        <div onClick={closeModal} className="modal-close x-mark">
          <Close />
        </div>
        {children}
      </div>
    </article>
  );
};

export default Modal;
