import useSpaceJet from "../../hooks/useSpaceJet.jsx";
import Spinner from "./Spinner.jsx";

const SpaceJet = () => {
  const { isLoading } = useSpaceJet();

  /* const isLoading = true; */

  return (
    <>
      <div
        className={`thank-you-container-spacejet${
          isLoading ? "" : " thank-you-container-spacejet-loading"
        }`}
      >
        <span className="thank-you-loader-text">
          Analyzing information, please wait a moment
        </span>

        <span className="typage-spinner-container">
          <Spinner />
        </span>
      </div>

      {!isLoading ? (
        <p className="thank-you-title">
          A Gutter professional will be reaching out very soon! Please take a
          look as you might be interested in any of the following
        </p>
      ) : null}

      <ins
        id="spacejetads"
        className={`thank-you-spacejetads${
          isLoading ? " thank-you-spacejetads-loading" : ""
        }`}
      ></ins>
    </>
  );
};

export default SpaceJet;
