import { ReactComponent as ArrowDown } from "../assets/svg/arrow-down.svg";
import PhoneSection from "./PhoneSection.jsx";
import LogoCard from "./LogoCard.jsx";
import FormCard from "./FormCard.jsx";
import useThankYou from "../hooks/useThankYou.jsx";
import { handleScroll } from "../helpers/handleScroll";

import "./MainContainer.scss";

const MainContainer = () => {
  /* useThankYou(); */

  return (
    <main className="main-container">
      <div className="background-img" />

      <PhoneSection />

      <section className="cards-container">
        <hgroup className="main-text">
          <h1>
            Find Local<strong> Gutter </strong>Installation and Repair By
            <strong> Zip Code</strong>
          </h1>

          <p>
            Connect and <strong>compare offers</strong> from the area's top
            professionals.
          </p>
        </hgroup>

        <FormCard />

        <article className="secondary-text">
          <p>No Obligation Quotes!</p>

          {/* <p>
            Our Vetted Contractors Have Completed Thousands of Window Projects!
          </p> */}
        </article>
      </section>

      <div className="more-info-link">
        <button type="button" onClick={() => handleScroll("more-info")}>
          More info <ArrowDown />
        </button>
      </div>
    </main>
  );
};

export default MainContainer;
