import useRadarAutocomplete from "../../hooks/useRadarAutocomplete.jsx";

import "./Input.scss";
import "./RadarAutocomplete.scss";

const RadarAutocomplete = ({ onSelection, disabled }) => {
  useRadarAutocomplete(onSelection, disabled);

  return <div id="autocomplete" className="input-container" />;
};

export default RadarAutocomplete;
