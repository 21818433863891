import { Link } from "react-router-dom";

import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-sections">
        <section>
          <article>
            <p>Important Disclosures</p>
            <p>
              This site is a free service to assist homeowners in connecting
              with local service contractors. All contractors are independent
              and this site does not warrant or guarantee any work performed. It
              is the responsibility of the homeowner to verify that the hired
              contractor furnishes the necessary license and insurance required
              for the work being performed. All persons depicted in a photo or
              video in any advertisement associated with this site are actors or
              models and not contractors affiliated with this site.
            </p>
          </article>
          <article>
            <p>
              Any service marks, logos, and trademarks (collectively the
              "Trademarks") displayed are registered and/or unregistered
              Trademarks of their respective owners. This site contains
              affiliate and partner links and may receive compensation for
              referrals or purchases made through our links. Subject matter
              within this website are copyrighted property of the owner of this
              site. This website and its owner is not responsible for any
              photographic or typographical errors.
            </p>
          </article>
        </section>

        <section className="copyright-bar">
          <p>© 2024 YourHomesGutters, All Rights Reserved. </p>
          <p>
            <Link to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>{" "}
            ⋅{" "}
            <Link to="/cookie-policy" target="_blank">
              Cookie Policy
            </Link>{" "}
            ⋅{" "}
            <Link to="/terms" target="_blank">
              Terms
            </Link>{" "}
            ⋅{" "}
            <Link to="/dnc" target="_blank">
              Do Not Call
            </Link>{" "}
            ⋅{" "}
            <a
              href="https://ceasesubscribe.com/unsub/Gy8p2RtSbVqV9cK0dKy0k2UcsOMHO6zq3CKbJWHM34TUTHkFrLwwfcC9jilgbJiP"
              target="_blank"
              rel="noreferrer"
            >
              Unsubscribe
            </a>{" "}
            ⋅{" "}
            <a
              href="#"
              onClick={() => {
                window.displayPreferenceModal();
                return false;
              }}
              id="termly-consent-preferences"
            >
              Consent Preferences
            </a>
          </p>
          <p>
            <a
              href="https://app.termly.io/notify/ad9a418f-0a79-4d1c-8400-6485061e050f"
              rel="nofollow noreferrer"
              target="_blank"
            >
              Do Not Sell or Share My Personal information
            </a>{" "}
            ⋅{" "}
            <a
              href="https://app.termly.io/notify/ad9a418f-0a79-4d1c-8400-6485061e050f"
              rel="nofollow noreferrer"
              target="_blank"
            >
              Limit the Use Of My Sensitive Personal Information
            </a>
          </p>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
