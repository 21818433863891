import { ReactComponent as Question } from "../../assets/svg/question.svg";

import "./Tooltip.scss";

const Tooltip = ({ text }) => {
  return (
    <div className="tooltip-container">
      <span className="tooltip-icon-container">
        <Question />
      </span>

      <div className="tooltip-text">{text}</div>
    </div>
  );
};

export default Tooltip;
