/* eslint-disable no-underscore-dangle */
import { useEffect } from "react";

import useLeadTrusted from "./useLeadTrusted.jsx";
import useFormContext from "./useFormContext.jsx";
import { deviceDetection } from "../helpers/deviceDetection";
import { getTyUrlParamsString, getUrlParams } from "../helpers/urlParams";
import { getCookie } from "../helpers/getCookie";

const useHiddenData = () => {
  const { handleHiddenData, handleUrlParams, handleTyUrlParams } =
    useFormContext();
  useLeadTrusted();

  useEffect(() => {
    try {
      const deviceData = deviceDetection();
      const urlParams = getUrlParams();
      const tyUrlParams = getTyUrlParamsString();
      let gaClientId = getCookie("_ga");
      const fbp = getCookie("_fbp");
      const fbc = getCookie("_fbc");

      if (gaClientId) gaClientId = gaClientId.substring(6);

      if (!urlParams._ef_transaction_id) {
        const efIdCookie = getCookie("ef_tid_c_a_37");

        if (efIdCookie) {
          const efIdArray = efIdCookie.split("|");
          const efId = efIdArray[efIdArray.length - 1];
          urlParams._ef_transaction_id = efId;
        }
      }

      const hiddenData = {
        ...deviceData,
        ga4_client_id: gaClientId,
        fbc,
        fbp,
      };

      handleHiddenData(hiddenData);
      handleUrlParams(urlParams);
      handleTyUrlParams(tyUrlParams);
    } catch (error) {
      console.log(error);
    }
  }, []);
};

export default useHiddenData;
