import ProgressBar from "./ProgressBar.jsx";
import CurrentForm from "./CurrentForm.jsx";

import "./FormCard.scss";

const FormCard = () => {
  return (
    <div className="form-card-outer" id="form-card-outer">
      <div className="form-card-container">
        <ProgressBar />

        <CurrentForm />

        <form className="hidden-form" />

        <input
          id="leadid_token"
          name="universal_leadid"
          type="hidden"
          value=""
        />
      </div>
    </div>
  );
};

export default FormCard;
