import { useEffect } from "react";

import { handleScrollViewport } from "../helpers/handleScroll";

const useAutoScroll = (id) => {
  useEffect(() => {
    handleScrollViewport(id);
  }, [id]);
};

export default useAutoScroll;
