import CommonBox from "./CommonBox.jsx";
import RadarAutocomplete from "../Inputs/RadarAutocomplete.jsx";
import Paragraph from "../Messages/Paragraph.jsx";
import Input from "../Inputs/Input.jsx";
import {
  addressValidation,
  cityValidation,
  stateValidation,
  zipCodeValidation,
} from "../../helpers/validations";

const RadarAutocompleteBox = ({
  setValue,
  errors,
  register,
  disabled,
  ...props
}) => {
  const handleAddress = (address) => {
    try {
      const newAddress = `${
        address.number ? address.number?.split("-")[0] : null
      } ${address.street ? `${address.street}` : null}`;

      setValue("address", newAddress);
      setValue("city", address.city);
      setValue("state", address.stateCode);
      setValue("zip_code", address.postalCode.substring(0, 5));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CommonBox>
      <RadarAutocomplete onSelection={handleAddress} disabled={disabled} />

      {/* VOLVER A VER bug, submit con error, renderiza error, seleccionar address valida, sigue renderizado el error */}
      {errors.address || errors.zip_code || errors.city || errors.state ? (
        <Paragraph text="Select a valid address" className="error" />
      ) : (
        <Paragraph text="hidden placeholder" className="error hidden" />
      )}

      <>
        <Input
          {...props}
          hidden={true}
          register={register("address", addressValidation)}
          error={errors.address?.message}
        />

        <Input
          {...props}
          hidden={true}
          register={register("city", cityValidation)}
          error={errors.city?.message}
        />

        <Input
          {...props}
          mask="aa"
          hidden={true}
          register={register("state", stateValidation)}
          error={errors.state?.message}
        />

        <Input
          {...props}
          mask="99999"
          hidden={true}
          register={register("zip_code", zipCodeValidation)}
          error={errors.zip_code?.message}
        />
      </>
    </CommonBox>
  );
};

export default RadarAutocompleteBox;
