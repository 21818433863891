/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-multi-assign */
/* eslint-disable no-use-before-define */

import { useEffect } from "react";

const { REACT_APP_ADPLUGG_ACCESS_CODE } = process.env;

const useAdplugg = () => {
  useEffect(() => {
    try {
      window.AdPlugg = AdPlugg = window.AdPlugg || [];
      var { AdPlugg } = window;

      AdPlugg.push({ command: "init", REACT_APP_ADPLUGG_ACCESS_CODE });

      AdPlugg.push({ command: "run" });
    } catch (error) {
      console.log(error);
    }
  });
};

export default useAdplugg;
