import { createContext, useState } from "react";

import {
  NUMBER_OF_QUESTIONS,
  NUMBER_OF_QUESTIONS_2,
  NUMBER_OF_QUESTIONS_3,
} from "../utils/constants";
import { solar } from "./res";

const FormContext = createContext();

const initialForm = {
  zip_code: null,
  material: null,
  scope: null,
  start_date: null,
  house_type: null,
  own_home: null,
  first_name: null,
  last_name: null,
  city: null,
  state: null,
  address: null,
  lat: null,
  lng: null,
  phoneSearch: null,
  email: null,
};

const initialHiddenData = {
  user_agent: null,
  is_mobile: null,
  ga4_client_id: null,
  fbc: null,
  fbp: null,
};

const initialTrustedLead = {
  trusted_form: null,
  lead_id: null,
};

const initialUrlParams = {};
const initialTyUrlParams = "";
const initialThankYouRanking = ["solar", "bathroom"];
const initialVariant = "1";
const initialSolarData = solar;
/* const initialSolarData = null; */

const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState(initialForm);
  const [hiddenData, setHiddenData] = useState(initialHiddenData);
  const [trustedLeadData, setTrustedLeadData] = useState(initialTrustedLead);
  const [urlParams, setUrlParams] = useState(initialUrlParams);
  const [tyUrlParams, setTyUrlParams] = useState(initialTyUrlParams);
  const [thankYouRanking, setThankYouRanking] = useState(
    initialThankYouRanking,
  );
  const [questionIndex, setQuestionIndex] = useState(0);
  const [variant, setVariant] = useState(initialVariant);
  const [solarData, setSolarData] = useState(initialSolarData);
  const [isLoading, setIsLoading] = useState(false);

  const handleHiddenData = (data) => {
    setHiddenData({
      ...hiddenData,
      ...data,
    });
  };

  const handleUrlParams = (data) => {
    setUrlParams(data);
  };

  const handleTyUrlParams = (data) => {
    setTyUrlParams(data);
  };

  const handleTrustedLead = (url) => {
    setTrustedLeadData(url);
  };

  const handleThankYouRanking = (ranking) => {
    setThankYouRanking(ranking);
  };

  const handleUpdateForm = (dataUpdated) => {
    setFormData({
      ...formData,
      ...dataUpdated,
    });
  };

  const handleNextQuestion = () => {
    if (variant === "1") {
      if (questionIndex < NUMBER_OF_QUESTIONS)
        setQuestionIndex((currentQuestion) => currentQuestion + 1);
    }
  };

  const handleVariant = (newVariant) => {
    setVariant(newVariant);
  };

  const handleSolarData = (newSolarData) => {
    setSolarData(newSolarData);
  };

  const handleIsLoading = (loadingValue) => {
    setIsLoading(loadingValue);
  };

  const data = {
    questionIndex,
    formData,
    hiddenData,
    trustedLeadData,
    urlParams,
    tyUrlParams,
    thankYouRanking,
    variant,
    solarData,
    isLoading,

    handleHiddenData,
    handleUrlParams,
    handleTyUrlParams,
    handleTrustedLead,
    handleThankYouRanking,
    handleUpdateForm,
    handleNextQuestion,
    handleVariant,
    handleSolarData,
    handleIsLoading,
  };

  return <FormContext.Provider value={data}>{children}</FormContext.Provider>;
};

export { FormProvider };
export default FormContext;
