import { useState } from "react";

import { customAxios } from "../helpers/customAxios";
import { createLeadEndpoint, doNotCallEndpoint } from "../utils/endpoints";
import useFormContext from "./useFormContext.jsx";
import { removePhoneMask } from "../helpers/removePhoneMask";
import { removeAddressCity } from "../helpers/removeAddressCity";

const { REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY } = process.env;

const useSubmit = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [errorDoNotCall, setErrorDoNotCall] = useState("");
  const [successDoNotCall, setSuccessDoNotCall] = useState(false);
  const {
    formData,
    hiddenData,
    urlParams,
    handleUpdateForm,
    handleNextQuestion,
    trustedLeadData,
    handleSolarData,
    handleIsLoading,
  } = useFormContext();

  const submitQuestion = (dataUpdated) => {
    handleUpdateForm(dataUpdated);
    handleNextQuestion();
  };

  const submitLastQuestion = async (dataUpdated) => {
    try {
      if (!submitAllowed) return;

      setSubmitAllowed(false);
      handleIsLoading(true);
      submitQuestion(dataUpdated);

      window.grecaptcha.ready(async () => {
        try {
          const googleRecaptchaToken = await window.grecaptcha.execute(
            REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY,
            {
              action: "submit",
            },
          );

          const dataToDispatch = {
            ...formData,
            ...dataUpdated,
            ...hiddenData,
            ...urlParams,
            ...trustedLeadData,
            //! VOLVER A VER descomentar
            /* phone: removePhoneMask(dataUpdated.phoneSearch), */
            address: removeAddressCity(dataUpdated.address),
            google_recaptcha_token: googleRecaptchaToken,
          };
          delete dataToDispatch.phoneSearch;

          console.log("llega");

          const { data } = await customAxios.post(
            createLeadEndpoint,
            dataToDispatch,
          );

          if (data?.solar) handleSolarData(data.solar);

          console.log(data.solar);

          handleIsLoading(false);
        } catch (error) {
          handleIsLoading(false);
        }
      });
    } catch (error) {
      handleIsLoading(false);
    }
  };

  const submitDoNotCall = async (dataUpdated) => {
    try {
      if (!submitAllowed) return;

      setSubmitAllowed(false);
      handleIsLoading(true);

      const dataToSend = {
        phone: removePhoneMask(dataUpdated.phoneSearch),
      };

      await customAxios.post(doNotCallEndpoint, dataToSend);

      setSuccessDoNotCall(true);
      handleIsLoading(false);
      setSubmitAllowed(true);
    } catch (error) {
      setErrorDoNotCall("There was an error, please try again later");
      handleIsLoading(false);
      setSubmitAllowed(true);
    }
  };

  return {
    submitQuestion,
    submitLastQuestion,
    submitDoNotCall,
    errorDoNotCall,
    successDoNotCall,
  };
};

export default useSubmit;

/* 
zip_code: '11000',
material: 'galvanized_steel',
scope: 'replace',
start_date: 'immediately',
house_type: 'mobile_home',
own_home: 'no',
first_name: 'asd',
last_name: 'sdg',
city: 'ny',
state: 'NY',
address: '444 nyn',
email: 'asd@asd.com',
user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36',
is_mobile: false,
ga4_client_id: '1949285693.1691443836',
_ef_transaction_id: '3db52fd455de4e459acb096e10a043a4',
trusted_form: 'https://cert.trustedform.com/8a6adde428179346fe59c1d5d733ba183119181c',
lead_id: 'D421076A-E5DD-73C4-0148-04891EEB2C64',
phone: '+5491123861282',
*/
