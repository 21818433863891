const getCookie = (name) => {
  const cookie = {};

  try {
    document.cookie.split(";").forEach((el) => {
      const splitCookie = el.split("=");
      const key = splitCookie[0].trim();
      const value = splitCookie[1];
      cookie[key] = value;
    });

    if (!cookie[name]) return "";

    return cookie[name];
  } catch (error) {
    return "";
  }
};

export { getCookie };
