import Spinner from "../Messages/Spinner.jsx";

import "./Button.scss";

const Button = ({ text, type = "button", disabled, isLoading, ...props }) => {
  return (
    <button {...props} type={type} disabled={disabled} className="button">
      {isLoading ? <Spinner /> : text}
    </button>
  );
};

export default Button;
