/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import RangeSlider from "react-range-slider-input";

import useFormContext from "../../hooks/useFormContext.jsx";

import "react-range-slider-input/dist/style.css";
import "./Slider.scss";
import Tooltip from "./Tooltip.jsx";

const Slider = () => {
  const { solarData: s } = useFormContext();

  const [sliderValue, setSliderValue] = useState([
    s.indexedBills[0],
    s.maxBill,
  ]);
  const [solarPotential, setSolarPotential] = useState(null);

  const [bill, setBill] = useState(s.indexedBills[0]);

  const handleOnInput = (value) => {
    setSliderValue(value);
  };

  useEffect(() => {
    if (sliderValue[0]) {
      const numberBill = parseInt(sliderValue[0], 10);

      for (let i = 0; i < s.indexedBills.length; i++) {
        if (numberBill <= s.indexedBills[0]) {
          setSolarPotential(s.solarPotential[s.indexedBills[0]]);
          break;
        }

        if (numberBill >= s.indexedBills[i]) {
          setSolarPotential(s.solarPotential[s.indexedBills[i]]);
        }
      }
    }
  }, [sliderValue]);

  /*   const handleBill = (e) => {
    setBill(e.target.value);
  };

  useEffect(() => {
    if (bill) {
      const numberBill = parseInt(bill, 10);

      for (let i = 0; i < s.indexedBills.length; i++) {
        if (numberBill <= s.indexedBills[0]) {
          setSolarPotential(s.solarPotential[s.indexedBills[0]]);
          break;
        }

        if (numberBill >= s.indexedBills[i]) {
          console.log(
            `${numberBill} >= ${s.indexedBills[i]} = ${
              numberBill >= s.indexedBills[i]
            }`,
          );

          console.log(s.indexedBills[i]);

          setSolarPotential(s.solarPotential[s.indexedBills[i]]);
        }
      }
    }
  }, [bill]); */

  return (
    <>
      <>
        <span className="solar-metric-container">
          <p className="solar-metric-text">
            Average monthly bill <span>$ {sliderValue[0]}</span>
          </p>
        </span>

        <div className="slider-container">
          <span className="slider-range-value">$ {s.minBill}</span>

          <RangeSlider
            className="single-thumb"
            thumbsDisabled={[false, true]}
            value={sliderValue}
            min={s.minBill}
            max={s.maxBill}
            onInput={handleOnInput}
            step={5}
          />

          <span className="slider-range-value">$ {s.maxBill}</span>
        </div>
      </>
      {/* <p>
        <span>Average monthly bill</span>
        <input type="text" onChange={handleBill} value={bill} />
      </> */}

      <span className="solar-metric-container">
        <Tooltip text="Maximum number of sunshine hours received per year, by any point on the roof. Sunshine hours are a measure of the total amount of insolation (energy) received per year. 1 sunshine hour = 1 kWh per kW (where kW refers to kW of capacity under Standard Testing Conditions)" />
        <p className="solar-metric-text">
          maxSunshineHoursPerYear <span>{s?.maxSunshineHoursPerYear}</span>
        </p>
      </span>

      <span className="solar-metric-container">
        <Tooltip text="Capacity, in watts, of the panel used in the calculations" />
        <p className="solar-metric-text">
          panelCapacityWatts <span>{s?.panelCapacityWatts} W</span>
        </p>
      </span>

      <span className="solar-metric-container">
        <Tooltip text="Height, in meters in portrait orientation, of the panel used in the calculations" />
        <p className="solar-metric-text">
          panelHeightMeters <span>{s?.panelHeightMeters} m</span>
        </p>
      </span>

      <span className="solar-metric-container">
        <Tooltip text="Width, in meters in portrait orientation, of the panel used in the calculations" />
        <p className="solar-metric-text">
          panelWidthMeters <span>{s?.panelWidthMeters} m</span>
        </p>
      </span>

      <span className="solar-metric-container">
        <Tooltip text="The expected lifetime, in years, of the solar panels. This is used in the financial calculations" />
        <p className="solar-metric-text">
          panelLifetimeYears <span>{s?.panelLifetimeYears} years</span>
        </p>
      </span>

      <p className="solar-metric-text">
        monthlyBill <span>$ {solarPotential?.monthlyBill}</span>
      </p>

      <span className="solar-metric-container">
        <Tooltip text="Total number of panels" />
        <p className="solar-metric-text">
          panelsCount{" "}
          <span>{solarPotential?.solarPanelConfig?.panelsCount}</span>
        </p>
      </span>

      <span className="solar-metric-container">
        <Tooltip text="How much sunlight energy this layout captures over the course of a year, in DC kWh, assuming the panels described above" />
        <p className="solar-metric-text">
          yearlyEnergyDcKwh{" "}
          <span>
            {solarPotential?.solarPanelConfig?.yearlyEnergyDcKwh} kWh / year
          </span>
        </p>
      </span>

      <span className="solar-metric-container">
        <Tooltip text="Utility bill for electricity not produced by solar, for the lifetime of the panels" />
        <p className="solar-metric-text">
          remainingLifetimeUtilityBill{" "}
          <span>
            ${" "}
            {
              solarPotential?.financialDetails?.remainingLifetimeUtilityBill
                ?.units
            }
          </span>
        </p>
      </span>

      <span className="solar-metric-container">
        <Tooltip text="Total cost of electricity the user would have paid over the lifetime period if they didn't install solar" />
        <p className="solar-metric-text">
          costOfElectricityWithoutSolar ${" "}
          <span>
            {
              solarPotential?.financialDetails?.costOfElectricityWithoutSolar
                ?.units
            }
          </span>
        </p>
      </span>

      <span className="solar-metric-container">
        <Tooltip text="How many AC kWh we think the solar panels will generate in their first year" />
        <p className="solar-metric-text">
          initialAcKwhPerYear{" "}
          <span>
            {solarPotential?.financialDetails?.initialAcKwhPerYear} kWh
          </span>
        </p>
      </span>
    </>
  );
};

export default Slider;
