import { Link } from "react-router-dom";

import useModal from "../../hooks/useModal.jsx";
import CompanyList from "../../pages/CompanyList.jsx";
import Modal from "../Messages/Modal.jsx";

import "./LeadidTcpa.scss";

const LeadidTcpa = ({ buttonText }) => {
  const [isModalOpen, openModal, closeModal] = useModal();

  return (
    <>
      <div className="tcpa-disclosure-container">
        <input type="hidden" id="leadid_tcpa_disclosure" />

        <label htmlFor="leadid_tcpa_disclosure" className="tcpa-disclosure">
          <p style={{ fontSize: "12px" }}>
            By clicking "{buttonText}", I am providing my electronic signature
            expressly authorizing yourhomesgutters.com, yourhomesconnection.com,
            and their agents to contact me by email, phone or text (including an
            automatic dialing system or artificial/pre-recorded voice) to
            confirm that I submit a request for certain service at the phone
            number above and to share my information with{" "}
            <button type="button" onClick={openModal}>
              contractors and other third-parties
            </button>{" "}
            even if my number is on any national or state do not call list. I
            understand I am not required to sign/agree to this as a condition to
            purchase. Message and data rates may apply. By clicking "
            {buttonText}", I also agree to the{" "}
            <Link to="/terms" target="_blank">
              Terms and Conditions
            </Link>{" "}
            and{" "}
            <Link to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
            .
          </p>
        </label>
      </div>

      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <CompanyList />
      </Modal>
    </>
  );
};

export default LeadidTcpa;
