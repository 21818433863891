import { useEffect } from "react";

import useFormContext from "./useFormContext.jsx";

const useLeadTrusted = () => {
  const { handleTrustedLead } = useFormContext();

  const getLeadId = () => {
    const intervalId = setInterval(() => {
      const certificateUrl = document.getElementById("xxTrustedFormCertUrl_1");
      const leadId = document.getElementById("leadid_token");

      if (certificateUrl && leadId && certificateUrl.value && leadId.value) {
        handleTrustedLead({
          trusted_form: certificateUrl.value,
          lead_id: leadId.value,
        });

        clearInterval(intervalId);
      }
    }, 100);
  };

  useEffect(() => {
    getLeadId();
  }, []);
};

export default useLeadTrusted;
