import { useForm } from "react-hook-form";

import useAutoScroll from "../../hooks/useAutoScroll.jsx";
import useFocus from "../../hooks/useFocus.jsx";
import useEverflow from "../../hooks/useEverflow.jsx";
import useAnalytics from "../../hooks/useAnalytics.jsx";
import useHiddenData from "../../hooks/useHiddenData.jsx";
import InputBox from "../InputBoxes/InputBox.jsx";
import RadarAutocompleteBox from "../InputBoxes/RadarAutocompleteBox.jsx";
import Button from "../Inputs/Button.jsx";
import LeadidTcpa from "../Inputs/LeadidTcpa.jsx";
import GoogleRecaptcha from "../Messages/GoogleRecaptcha.jsx";
import { handlePhoneFormat } from "../../helpers/handlePhoneFormat";
import {
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  phoneValidation,
} from "../../helpers/validations";

import "./Form.scss";
import "./Personal.scss";

const Personal = ({ onSubmit, isLoading }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    setFocus,
  } = useForm();

  useAutoScroll("form-card-outer");
  useFocus(setFocus, "first_name");
  useHiddenData();
  useEverflow();
  useAnalytics("solarquestions");

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="form-container form-smaller-inputs"
    >
      <div className="personal-inputs-grid">
        {/* <Paragraph text="Based on your gutters project details, take the next step! Complete the form for custom quotes" />

        <InputBox
          aria-label="First Name"
          placeholder="First Name"
          disabled={isSubmitting || isLoading}
          register={register("first_name", firstNameValidation)}
          error={errors.first_name?.message}
        />

        <InputBox
          aria-label="Last Name"
          placeholder="Last Name"
          disabled={isSubmitting || isLoading}
          register={register("last_name", lastNameValidation)}
          error={errors.last_name?.message}
        /> */}

        <RadarAutocompleteBox
          setValue={setValue}
          register={register}
          errors={errors}
          disabled={isSubmitting || isLoading}
        />

        {/* <InputBox
          aria-label="Phone Number"
          placeholder="Phone"
          disabled={isSubmitting || isLoading}
          inputMode="numeric"
          onKeyDown={handlePhoneFormat}
          mask="(999) 999-9999"
          register={register("phoneSearch", phoneValidation)}
          error={errors.phoneSearch?.message}
        />

        <InputBox
          aria-label="Email"
          placeholder="Email"
          disabled={isSubmitting || isLoading}
          register={register("email", emailValidation)}
          error={errors.email?.message}
        /> */}
      </div>

      <LeadidTcpa buttonText="SUBMIT" />

      <Button
        text="SUBMIT"
        type="submit"
        disabled={isSubmitting || isLoading}
        isLoading={isSubmitting || isLoading}
      />

      <GoogleRecaptcha />
    </form>
  );
};

export default Personal;
