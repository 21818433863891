import Axios from "axios";
import { setupCache, buildWebStorage } from "axios-cache-interceptor";

const { REACT_APP_API_URL, REACT_APP_API_AUTHORIZATION_TOKEN } = process.env;

const newAxios = Axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    Authorization: REACT_APP_API_AUTHORIZATION_TOKEN,
  },
});

const customAxios = setupCache(newAxios, {
  storage: buildWebStorage(localStorage, "axios-cache:"),
});

customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(new Error("There was an error, please try again"));
    }
    return Promise.reject(error.response.data);
  },
);

export { customAxios };
