import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import useIpqsFingerprint from "./hooks/useIpqsFingerprint.jsx";
import { FormProvider } from "./contexts/FormContext.jsx";
import Variant from "./components/Variant.jsx";
import Home from "./pages/Home.jsx";

import "./App.css";

const CompanyList = lazy(() => import("./pages/CompanyList.jsx"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy.jsx"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy.jsx"));
const Terms = lazy(() => import("./pages/Terms.jsx"));
const DoNotCall = lazy(() => import("./pages/DoNotCall.jsx"));
const NotFound = lazy(() => import("./pages/NotFound.jsx"));

function App() {
  /* useIpqsFingerprint(); */

  return (
    <div className="App">
      <FormProvider>
        <Variant />
        <Suspense>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/company-list" element={<CompanyList />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/dnc" element={<DoNotCall />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </FormProvider>
    </div>
  );
}

export default App;
