const removePhoneMask = (phone) => {
  const phoneWithoutMask = phone
    .replaceAll(" ", "")
    .replaceAll("-", "")
    .replaceAll("(", "")
    .replaceAll(")", "");

  return phoneWithoutMask;
};

export { removePhoneMask };
