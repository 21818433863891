import "./MoreInfo.scss";

const MoreInfo = () => {
  return (
    <div className="more-info-container">
      <section className="more-info-section" id="more-info">
        <article>
          <h3>GUTTER INSTALLS & REPLACEMENT</h3>
          <p>
            Need your home Gutters installed or repaired? By completing the form
            below, you’ll have a list of estimates for either or both at your
            fingertips! Whether you’re looking to get home Gutter Guards or
            repair your current gutter system - we can help.
          </p>
        </article>

        <article>
          <h3>GUTTER REPAIR & CLEANING</h3>
          <p>
            Home Gutter repair and cleaning can entail quite a bit. It starts
            with the inspection of all gutters and downspouts. Once that’s been
            done, all aspects of the drainage system are cleared and flushed
            with water to ensure proper drainage. Minor repairs may need to be
            done (depends on each individual home of course). All accumulated
            debris is then removed and a final walk-through inspection is
            performed to evaluate the cleanup of the job.
          </p>
        </article>

        <article>
          <h3>GUTTER PROTECTION</h3>
          <p>
            In addition to Home Gutters installation services, Home Gutter
            protection systems are also available. Complete Home Gutter rain
            protection works to protect your gutters with a self-cleaning design
            that can handle more rain water than any solid hood covering.
          </p>
        </article>

        <article>
          <h3>HOW ARE GUTTERS REPAIRED?</h3>
          <p>
            Every Home Gutters repair is different, and all requests will
            require a site visit from a professional. Generally, gutter repair
            can include:
          </p>

          <section>
            <ul>
              <li>Screwing miters (corner pieces) together</li>
              <li>
                Checking for loose downspouts, gutters, missing parts or leaks
              </li>
              <li>
                Nailing rain gutter to the fascia board (if sagging or pulling
                away)
              </li>
              <li>Caulking for dripping/ leaking rain gutters</li>
              <li>Adding straps for downspouts (pulling away from the wall)</li>
              <li>
                Diverting downspouts to change direction of water
                flow/drainMaterials often used during repair are screws,
                caulking, nails, aluminum straps, and aluminum end caps.
              </li>
            </ul>
          </section>
        </article>

        <article>
          <h3>HOW OFTEN DO GUTTERS NEED CLEANING?</h3>
          <p>
            Home Gutter cleaning is typically done annually or bi-annually (once
            before winter and again before summer for the latter). This depends
            on factors such as landscaping in surrounding areas and how much
            debris gets caught in your system. It may be time for a cleaning if
            you have a waterfall effect or the downspouts aren’t draining.
            Service providers often recommend a cleaning first, with all repairs
            that need to be done (separately).If a home gutter needs to be
            cleaned - a professional will customarily reseal all corners, end
            caps and drop outlets, then offer a gutter guard so an extensive
            cleaning isn’t required again.
          </p>
        </article>

        <article>
          <h3>GUTTER REPAIR SERVICES</h3>
          <p>
            Your home’s rain gutters serve to divert water away from the roof in
            order to prevent water damage to both the inside and outside of your
            property. But when they don’t work properly, water can pool on your
            roof or seep into your basement, leading to rot and mold. Home
            Gutter rain repair, gutter cleaning and gutter replacement are all
            typical maintenance services performed to ensure optimal function
            and appearance of a house. Regular and consistent home gutter repair
            upkeep help keep everything running smoothly. Consider a local pro
            to help you complete a fast, reliable home gutter repair! Home
            Gutter repair is usually simple and inexpensive. Leaky gutters can
            result from rust and loose, or even missing parts. Small holes can
            be patched with plastic roofing cement, while larger holes may need
            metal flashing. When it comes to home gutter accessories, the
            possibilities are endless. There’s a wide range of flexible
            downspout extensions, gutter guards, and more. Gutters are a
            prominent feature of your home, so gutter repair is also essential
            for curb appeal. Materials are always available in an array of
            colors to match your home's appearance.
          </p>
        </article>

        <article>
          <h3>LOCAL GUTTER REPAIR</h3>
          <p>
            Let a local, experienced professional perform a site review to
            determine the right course of action for your leaking or damaged
            home gutters. A service provider will listen to your needs and work
            with you to find a proper solution!
          </p>
        </article>
      </section>
    </div>
  );
};

export default MoreInfo;
