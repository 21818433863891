import useEverflow from "../../hooks/useEverflow.jsx";
import useAnalytics from "../../hooks/useAnalytics.jsx";
import useFormContext from "../../hooks/useFormContext.jsx";
import Spinner from "./Spinner.jsx";
import Slider from "./Slider.jsx";

import "./ThankYou.scss";

const ThankYou2 = () => {
  const { solarData, isLoading } = useFormContext();

  useEverflow(404);
  useAnalytics("typage");

  if (isLoading)
    return (
      <div className="thank-you-container thank-you-container-v2">
        <span className="thank-you-loader-text">
          Analyzing information, please wait a moment
        </span>

        <span className="typage-spinner-container">
          <Spinner />
        </span>
      </div>
    );

  return (
    <div className="thank-you-container thank-you-container-v2">
      {solarData ? (
        <>
          {/* <div className="typage-disclosure">
            <p>
              Please remember this is NOT legal advice. This is a response
              generated based on the information given to us, and what we THINK
              you COULD be entitled to. Always consult with a legal
              professional.
            </p>
            <p>
              You can connect with one at{" "}
              <a href="tel:18555992058" className="thank-you-phone">
                1-855-599-2058
              </a>
            </p>
          </div> */}

          <Slider />

          {/* <div className="typage-disclosure">
            <p>
              Please remember this is NOT legal advice. This is a response
              generated based on the information given to us, and what we THINK
              you COULD be entitled to. Always consult with a legal
              professional.
            </p>
            <p>
              You can connect with one at{" "}
              <a href="tel:18555992058" className="thank-you-phone">
                1-855-599-2058
              </a>
            </p>
          </div> */}
        </>
      ) : (
        <>
          {/* <p className="typage-answer">
            A personal injury lawyer will be reaching out very soon! Please take
            a moment and check out some of our sponsored partner's offers
          </p> */}
          <p className="thank-you-title">
            A Gutter professional will be reaching out very soon! Please take a
            look as you might be interested in any of the following
          </p>

          <div className="adplugg-tag" data-adplugg-zone="yhg_typage"></div>
        </>
      )}
    </div>
  );
};

export default ThankYou2;
