import { useForm } from "react-hook-form";

import useEverflow from "../../hooks/useEverflow.jsx";
import useAnalytics from "../../hooks/useAnalytics.jsx";
import Button from "../Inputs/Button.jsx";
import Select from "../Inputs/Select.jsx";
import {
  materialSelectOptions,
  scopeSelectOptions,
  startDateSelectOptions,
} from "../../utils/enumOptions";

import "./Form.scss";

const ProjectDetails = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEverflow();
  useAnalytics("name");

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-container">
      <div className="form-inputs-container">
        <h3>What is the nature of your gutters project?</h3>

        <Select
          name="scope"
          register={register}
          error={errors.scope}
          options={scopeSelectOptions}
          placeholder="Select Option"
        />
      </div>

      <div className="form-inputs-container">
        <h3>What material do you prefer?</h3>

        <Select
          name="material"
          register={register}
          error={errors.material}
          options={materialSelectOptions}
          placeholder="Select Option"
        />
      </div>

      <div className="form-inputs-container">
        <h3>When do you plan to start this project?</h3>

        <Select
          name="start_date"
          register={register}
          error={errors.start_date}
          options={startDateSelectOptions}
          placeholder="Select Option"
        />
      </div>

      <Button text="NEXT" type="submit" disabled={Object.keys(errors).length} />
    </form>
  );
};

export default ProjectDetails;
