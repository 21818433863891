export const solar = {
  maxArrayPanelsCount: 214,
  maxArrayAreaMeters2: 350.27518,
  maxSunshineHoursPerYear: 1158.2322,
  carbonOffsetFactorKgPerMwh: 482.10336,
  panelCapacityWatts: 250,
  panelHeightMeters: 1.65,
  panelWidthMeters: 0.992,
  panelLifetimeYears: 20,
  minBill: 0,
  maxBill: 100,
  indexedBills: [35, 40, 45, 50, 60, 70, 80, 90, 100],
  solarPotential: {
    35: {
      monthlyBill: 35,
      index: 0,
      solarPanelConfig: {
        panelsCount: 4,
        yearlyEnergyDcKwh: 1177.5903,
        roofSegmentSummaries: [
          {
            pitchDegrees: 1.2233875,
            azimuthDegrees: 202.84705,
            panelsCount: 4,
            yearlyEnergyDcKwh: 1177.5903,
            segmentIndex: 4,
          },
        ],
      },
      financialDetails: {
        initialAcKwhPerYear: 1000.9518,
        remainingLifetimeUtilityBill: { currencyCode: "USD", units: "4561" },
        federalIncentive: { currencyCode: "USD", units: "1355" },
        stateIncentive: { currencyCode: "USD", units: "1303" },
        utilityIncentive: { currencyCode: "USD", units: "500" },
        lifetimeSrecTotal: { currencyCode: "USD" },
        costOfElectricityWithoutSolar: { currencyCode: "USD", units: "9499" },
        netMeteringAllowed: true,
        solarPercentage: 95.2938,
        percentageExportedToGrid: 0,
      },
    },
    40: {
      monthlyBill: 40,
      index: 1,
      solarPanelConfig: {
        panelsCount: 5,
        yearlyEnergyDcKwh: 1468.5089,
        roofSegmentSummaries: [
          {
            pitchDegrees: 1.2233875,
            azimuthDegrees: 202.84705,
            panelsCount: 5,
            yearlyEnergyDcKwh: 1468.5089,
            segmentIndex: 4,
          },
        ],
      },
      financialDetails: {
        initialAcKwhPerYear: 1248.2327,
        remainingLifetimeUtilityBill: { currencyCode: "USD", units: "4843" },
        federalIncentive: { currencyCode: "USD", units: "1502" },
        stateIncentive: { currencyCode: "USD", units: "1444" },
        utilityIncentive: { currencyCode: "USD", units: "625" },
        lifetimeSrecTotal: { currencyCode: "USD" },
        costOfElectricityWithoutSolar: { currencyCode: "USD", units: "11001" },
        netMeteringAllowed: true,
        solarPercentage: 93.20503,
        percentageExportedToGrid: 0,
      },
    },
    45: {
      monthlyBill: 45,
      index: 2,
      solarPanelConfig: {
        panelsCount: 6,
        yearlyEnergyDcKwh: 1749.8727,
        roofSegmentSummaries: [
          {
            pitchDegrees: 0.81958634,
            azimuthDegrees: 0,
            panelsCount: 1,
            yearlyEnergyDcKwh: 281.36386,
            segmentIndex: 0,
          },
          {
            pitchDegrees: 1.2233875,
            azimuthDegrees: 202.84705,
            panelsCount: 5,
            yearlyEnergyDcKwh: 1468.5089,
            segmentIndex: 4,
          },
        ],
      },
      financialDetails: {
        initialAcKwhPerYear: 1487.3918,
        remainingLifetimeUtilityBill: { currencyCode: "USD", units: "5166" },
        federalIncentive: { currencyCode: "USD", units: "1649" },
        stateIncentive: { currencyCode: "USD", units: "1585" },
        utilityIncentive: { currencyCode: "USD", units: "750" },
        lifetimeSrecTotal: { currencyCode: "USD" },
        costOfElectricityWithoutSolar: { currencyCode: "USD", units: "12503" },
        netMeteringAllowed: true,
        solarPercentage: 91.35337,
        percentageExportedToGrid: 0,
      },
    },
    50: {
      monthlyBill: 50,
      index: 3,
      solarPanelConfig: {
        panelsCount: 7,
        yearlyEnergyDcKwh: 2031.1559,
        roofSegmentSummaries: [
          {
            pitchDegrees: 0.81958634,
            azimuthDegrees: 0,
            panelsCount: 1,
            yearlyEnergyDcKwh: 281.36386,
            segmentIndex: 0,
          },
          {
            pitchDegrees: 1.2233875,
            azimuthDegrees: 202.84705,
            panelsCount: 6,
            yearlyEnergyDcKwh: 1749.792,
            segmentIndex: 4,
          },
        ],
      },
      financialDetails: {
        initialAcKwhPerYear: 1726.4825,
        remainingLifetimeUtilityBill: { currencyCode: "USD", units: "5489" },
        federalIncentive: { currencyCode: "USD", units: "1796" },
        stateIncentive: { currencyCode: "USD", units: "1726" },
        utilityIncentive: { currencyCode: "USD", units: "875" },
        lifetimeSrecTotal: { currencyCode: "USD" },
        costOfElectricityWithoutSolar: { currencyCode: "USD", units: "14006" },
        netMeteringAllowed: true,
        solarPercentage: 90.05633,
        percentageExportedToGrid: 0,
      },
    },
    60: {
      monthlyBill: 60,
      index: 4,
      solarPanelConfig: {
        panelsCount: 10,
        yearlyEnergyDcKwh: 2869.8145,
        roofSegmentSummaries: [
          {
            pitchDegrees: 0.81958634,
            azimuthDegrees: 0,
            panelsCount: 4,
            yearlyEnergyDcKwh: 1120.0225,
            segmentIndex: 0,
          },
          {
            pitchDegrees: 1.2233875,
            azimuthDegrees: 202.84705,
            panelsCount: 6,
            yearlyEnergyDcKwh: 1749.792,
            segmentIndex: 4,
          },
        ],
      },
      financialDetails: {
        initialAcKwhPerYear: 2439.3423,
        remainingLifetimeUtilityBill: { currencyCode: "USD", units: "4974" },
        federalIncentive: { currencyCode: "USD", units: "2236" },
        stateIncentive: { currencyCode: "USD", units: "2150" },
        utilityIncentive: { currencyCode: "USD", units: "1250" },
        lifetimeSrecTotal: { currencyCode: "USD" },
        costOfElectricityWithoutSolar: { currencyCode: "USD", units: "17010" },
        netMeteringAllowed: true,
        solarPercentage: 97.79094,
        percentageExportedToGrid: 0,
      },
    },
    70: {
      monthlyBill: 70,
      index: 5,
      solarPanelConfig: {
        panelsCount: 12,
        yearlyEnergyDcKwh: 3430.8633,
        roofSegmentSummaries: [
          {
            pitchDegrees: 0.81958634,
            azimuthDegrees: 0,
            panelsCount: 6,
            yearlyEnergyDcKwh: 1681.0713,
            segmentIndex: 0,
          },
          {
            pitchDegrees: 1.2233875,
            azimuthDegrees: 202.84705,
            panelsCount: 6,
            yearlyEnergyDcKwh: 1749.792,
            segmentIndex: 4,
          },
        ],
      },
      financialDetails: {
        initialAcKwhPerYear: 2916.234,
        remainingLifetimeUtilityBill: { currencyCode: "USD", units: "5590" },
        federalIncentive: { currencyCode: "USD", units: "2529" },
        stateIncentive: { currencyCode: "USD", units: "2432" },
        utilityIncentive: { currencyCode: "USD", units: "1500" },
        lifetimeSrecTotal: { currencyCode: "USD" },
        costOfElectricityWithoutSolar: { currencyCode: "USD", units: "20015" },
        netMeteringAllowed: true,
        solarPercentage: 95.13769,
        percentageExportedToGrid: 0,
      },
    },
    80: {
      monthlyBill: 80,
      index: 6,
      solarPanelConfig: {
        panelsCount: 14,
        yearlyEnergyDcKwh: 3990.03,
        roofSegmentSummaries: [
          {
            pitchDegrees: 0.81958634,
            azimuthDegrees: 0,
            panelsCount: 8,
            yearlyEnergyDcKwh: 2240.238,
            segmentIndex: 0,
          },
          {
            pitchDegrees: 1.2233875,
            azimuthDegrees: 202.84705,
            panelsCount: 6,
            yearlyEnergyDcKwh: 1749.792,
            segmentIndex: 4,
          },
        ],
      },
      financialDetails: {
        initialAcKwhPerYear: 3391.5256,
        remainingLifetimeUtilityBill: { currencyCode: "USD", units: "6162" },
        federalIncentive: { currencyCode: "USD", units: "2822" },
        stateIncentive: { currencyCode: "USD", units: "2714" },
        utilityIncentive: { currencyCode: "USD", units: "1750" },
        lifetimeSrecTotal: { currencyCode: "USD" },
        costOfElectricityWithoutSolar: { currencyCode: "USD", units: "23019" },
        netMeteringAllowed: true,
        solarPercentage: 93.527824,
        percentageExportedToGrid: 0,
      },
    },
    90: {
      monthlyBill: 90,
      index: 7,
      solarPanelConfig: {
        panelsCount: 17,
        yearlyEnergyDcKwh: 4829.2275,
        roofSegmentSummaries: [
          {
            pitchDegrees: 0.81958634,
            azimuthDegrees: 0,
            panelsCount: 11,
            yearlyEnergyDcKwh: 3079.4355,
            segmentIndex: 0,
          },
          {
            pitchDegrees: 1.2233875,
            azimuthDegrees: 202.84705,
            panelsCount: 6,
            yearlyEnergyDcKwh: 1749.792,
            segmentIndex: 4,
          },
        ],
      },
      financialDetails: {
        initialAcKwhPerYear: 4104.8438,
        remainingLifetimeUtilityBill: { currencyCode: "USD", units: "5561" },
        federalIncentive: { currencyCode: "USD", units: "3263" },
        stateIncentive: { currencyCode: "USD", units: "3137" },
        utilityIncentive: { currencyCode: "USD", units: "2125" },
        lifetimeSrecTotal: { currencyCode: "USD" },
        costOfElectricityWithoutSolar: { currencyCode: "USD", units: "26024" },
        netMeteringAllowed: true,
        solarPercentage: 98.02754,
        percentageExportedToGrid: 0,
      },
    },
    100: {
      monthlyBill: 100,
      index: 8,
      solarPanelConfig: {
        panelsCount: 19,
        yearlyEnergyDcKwh: 5387.82,
        roofSegmentSummaries: [
          {
            pitchDegrees: 0.81958634,
            azimuthDegrees: 0,
            panelsCount: 13,
            yearlyEnergyDcKwh: 3638.028,
            segmentIndex: 0,
          },
          {
            pitchDegrees: 1.2233875,
            azimuthDegrees: 202.84705,
            panelsCount: 6,
            yearlyEnergyDcKwh: 1749.792,
            segmentIndex: 4,
          },
        ],
      },
      financialDetails: {
        initialAcKwhPerYear: 4579.647,
        remainingLifetimeUtilityBill: { currencyCode: "USD", units: "6133" },
        federalIncentive: { currencyCode: "USD", units: "3556" },
        stateIncentive: { currencyCode: "USD", units: "3419" },
        utilityIncentive: { currencyCode: "USD", units: "2375" },
        lifetimeSrecTotal: { currencyCode: "USD" },
        costOfElectricityWithoutSolar: { currencyCode: "USD", units: "29028" },
        netMeteringAllowed: true,
        solarPercentage: 96.455765,
        percentageExportedToGrid: 0,
      },
    },
  },
};
