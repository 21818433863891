import { SRC_ID_TYPAGE } from "../utils/constants";

const getUrlParams = () => {
  const paramsObject = {};

  if (window.location.search)
    window.location.search
      .slice(1)
      .split("&")
      .forEach(function (item) {
        paramsObject[item.split("=")[0]] = item.split("=").slice(1).join("");
      });

  return paramsObject;
};

/*
&affiliate_id=AFF_ID&affiliate_source=AFF_SRC&_ef_transaction_id=TRANSACTION_ID
*/

const getTyUrlParamsString = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const affiliateId = urlParams.get("affiliate_id");
  const affiliateSource = urlParams.get("affiliate_source");
  const sub1 = urlParams.get("_ef_transaction_id");

  const tyParams = `?source_id=${SRC_ID_TYPAGE}_typage_${affiliateId || ""}_${
    affiliateSource || ""
  }${sub1 ? `&sub1=${sub1}` : ""}`;

  return tyParams;
};

export { getUrlParams, getTyUrlParamsString };

/*
params
?utm_source=UTMSRC&utm_medium=UTMMDM&utm_content=UTMCNT&utm_term=UTMTRM&utm_id=UTMID&utm_campaign=UTMCPN&fbclid=FBID&gclid=GID&_ef_transaction_id=EFTID&affiliate_id=AFFID&affiliate_source=AFFSRC&affiliate_refer=AFFRFR&oid=OID&aff_sub5=SUB5&aff_sub4=SUB4&aff_sub3=SUB3&aff_sub2=SUB2&aff_sub1=SUB1
*/
