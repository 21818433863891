import InputMask from "react-input-mask";

import "./Input.scss";

const Input = ({
  inputMode = "text",
  mask = null,
  hidden,
  register,
  ...props
}) => {
  return (
    <div className={`input-container${hidden ? " hidden-input" : ""}`}>
      <InputMask
        {...props}
        inputMode={inputMode}
        mask={mask}
        alwaysShowMask={false}
        maskPlaceholder=""
        {...register}
        className="input"
        type="text"
        autoComplete="off"
        onPaste={(e) => e.preventDefault()}
      />
    </div>
  );
};

export default Input;
