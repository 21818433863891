import { useEffect } from "react";

import useFormContext from "./useFormContext.jsx";

const useEverflow = (eventId = 419) => {
  const { questionIndex } = useFormContext();

  useEffect(() => {
    try {
      const event = eventId === 404 ? eventId : eventId + questionIndex;

      if (questionIndex !== 0) {
        window.EF.conversion({
          offer_id: window.EF.urlParameter("oid"),
          aid: 37,
          transaction_id: window.EF.urlParameter("_ef_transaction_id"),
          adv_event_id: event,

          verification_token: "PA74Zi9iSsvL2XUzlMJrnRA7W9LZNI",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [eventId]);
};

export default useEverflow;
