import React from "react";

import "./Select.scss";

const Select = ({
  name,
  register,
  options,
  placeholder,
  isLoading,
  ...props
}) => {
  return (
    <div className="input-container">
      <select
        {...props}
        aria-label={name}
        className="input select"
        disabled={isLoading || !options.length}
        {...register(name, {
          required: {
            value: true,
            message: "Select an option",
          },
        })}
      >
        <option value="">{placeholder}</option>

        {options.length &&
          React.Children.toArray(
            options.map((option) => <option value={option}>{option}</option>),
          )}
      </select>
    </div>
  );
};

export default Select;
