import MoreInfo from "../components/MoreInfo.jsx";
import MainContainer from "../components/MainContainer.jsx";
import Footer from "../components/Footer.jsx";

const Home = () => {
  return (
    <>
      <MainContainer />
      <MoreInfo />
      <Footer />
    </>
  );
};

export default Home;
