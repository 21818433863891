/* eslint-disable no-plusplus */
import { useEffect } from "react";

import useFormContext from "./useFormContext.jsx";

const useEverflowPixel = () => {
  const { tyUrlParams } = useFormContext();

  const appendUrlParams = () => {
    const intervalId = setInterval(() => {
      const ads = document.getElementsByClassName("adplugg-ad-wrapper");

      if (ads.length) {
        for (let i = 0; i < ads.length; i++) {
          const a = ads[i].getElementsByTagName("a")[0];
          const img = ads[i].getElementsByTagName("img")[1];

          if (a && img) {
            const imgSrcArray = a.href.split("/");
            imgSrcArray.splice(3, 0, "i");
            const imgSrc = imgSrcArray.join("/");
            a.href += tyUrlParams;
            img.src = imgSrc + tyUrlParams;
          }
        }

        clearInterval(intervalId);
      }
    }, 100);
  };

  useEffect(() => {
    appendUrlParams();
  }, []);
};

export default useEverflowPixel;
