import { useEffect } from "react";

import useFormContext from "./useFormContext.jsx";

const useAnalytics = (trackName) => {
  const { variant, questionIndex } = useFormContext();

  useEffect(() => {
    try {
      const eventName = `${trackName}${questionIndex}${
        variant === "1" ? "" : `_v${variant}`
      }`;

      if (questionIndex !== 0) {
        window.fbq("trackCustom", eventName);

        window.gtag("event", eventName);
      }
    } catch (error) {
      console.log(error);
    }
  }, [trackName]);
};

export default useAnalytics;
